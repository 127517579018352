import React from 'react';

const MainPage = () => {
    return (
        <div className='main-page'>
            <div className='sub-header'>
                <div className="about-block-info">
                    <div>
                        <p className='profile-title-text'>Karnaukh <br/> Konstantin</p>
                        <p className='profile-title-sub-text'>full stack developer</p>
                    </div>
                    <div>
                        <img src="mobile_avatar.png" alt="" className='mobile-avatar'/>
                    </div>
                </div>
            </div>

            <div className='about-me'>
                <p className="profile-description">
                    Hello! <br/>
                    My name is Konstantin and I'm a full-stack developer. <br />
                    I started my journey in code in 2017 and it was a new page of my life! <br />
                    I love code, coffee and black chocolate, metal music and cars. <br />
                    Nice to meet you!
                </p>
            </div>
        </div>
    );
}

export default MainPage