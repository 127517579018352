import { Link } from "react-router-dom";
const MenuSideDrawer = () => {
    return (
        <div className='menu-block'>
            <div className='menu-nav'>
                <Link to="/"><p className='text-menu'>about</p></Link>
                <Link to="/skills"><p className='text-menu'>skills</p></Link>
                <Link to="/experience"><p className='text-menu'>experience</p></Link>
                <Link to="/links"><p className='text-menu'>links</p></Link>
            </div>
        </div>
    );
}

export default MenuSideDrawer;