import React from "react";

const ProjectPart = (props) => {
    const { title, value } = props;

    return (
        <div className='project-info-margin'>
            <span className='project-info-text'>{title}</span>
            <span className='project-sub-text'>&nbsp;{value}</span>
        </div>
    );
}

export default ProjectPart;