import React from "react";
import ProjectPart from "./ProjectPart";

const ProjectInfo = (props) => {
    const { project } = props;

    return (
        <div className='project-body'>
            <span className='company-name'>{project.name}: </span>
            <span className='project-type'>&nbsp;/ {project.type}</span>

            <div>
                <a href={project.link} target="_blank">
                    <p className='project-sub-text'>{project.link}</p>
                </a>

                <ProjectPart title="client location:" value={project.clientLocation}/>
                <ProjectPart title="stack:" value={project.stack}/>
                <ProjectPart title="additions:" value={project.additions}/>
            </div>

            <ProjectPart title="description:" value={project.description}/>
            <ProjectPart title="dev process:" value={project.devProcess}/>

            <div className='project-info-margin'>
                <span className='project-info-text'>my technical value:</span>
                <div className='project-list-block'>
                    {project.implemented?.length > 0 && project.implemented.map((feature, fIndex) => {
                        return (
                            <div key={fIndex}>
                                <span className='project-name'>&#x2022; {feature}</span>
                            </div>
                        );
                    })
                    }
                </div>
            </div>

            <ProjectPart title="conclusion:" value={project.conclusion}/>
            <ProjectPart title="work period:" value={project.workPeriod}/>
        </div>
    );
}

export default ProjectInfo;