import React from 'react';

const LinksPage = () => {
    return (
        <div className='links-page'>
            <div className='sub-header'>
                <p className='profile-title-text'>My social</p>
                <p className='profile-title-text'>Links</p>
                <p className='profile-title-sub-text'>contacts</p>
            </div>

            <div className='links-block'>
                <a href="https://t.me/kostRumus" target="_blank">
                    <p className="text-links"><i className="fa fa-paper-plane-o favicon"></i>https://t.me/kostRumus</p>
                </a>
                <a href="https://www.linkedin.com/in/karnaukh-kost" target="_blank">
                    <p className="text-links"><i className="fa fa-linkedin favicon"></i>https://www.linkedin.com/in/karnaukh-kost
                    </p>
                </a>
                <a href="mailto: kost.trikevelo@gmail.com">
                    <p className="text-links"><i className="fa fa-envelope-o favicon"></i>kost.trikevelo@gmail.com</p>
                </a>
            </div>
        </div>
    );
}

export default LinksPage;