import React, { useState } from 'react';
import * as experience from '../data/project-info.json';
import ProjectInfo from "./ProjectInfo";
const ExperiencePage = () => {
    const data = {experience};
    const companies = data.experience.companies;
    const [company, setCompany] = useState(1);
    const [selectedProject, setProject] = useState(companies[0].projects[0]);

    const handleProject = (companyId, project) => {
        setCompany(companyId);
        setProject(project);
    }

    return (
        <div className='experience-page'>
            <div className='sub-header'>
                <p className='profile-title-text'>My</p>
                <p className='profile-title-text'>Experience</p>
                <p className='profile-title-sub-text'> code journey timeline</p>
            </div>

            <div className='experience-block'>
                    <div className='company-list-block'>
                        {companies.map((company, cIndex) => {
                          return (
                              <div className='company-block' key={cIndex}>
                                  <p className='company-name'>
                                      {company.name}
                                  </p>
                                  <p className='sub-text-experience'>{company.period}</p>

                                  <div className='project-list-block'>
                                      {company.projects?.length > 0 && company.projects.sort((a, b) => parseFloat(b.id) - parseFloat(a.id)).map((project, pIndex) => {
                                          return (
                                              <div key={pIndex} onClick={() => handleProject(company.id, project)}>
                                                  <span className='project-name'>
                                                    &#x2022; {project.name}
                                                  </span>
                                              </div>
                                          );
                                        })
                                      }
                                  </div>
                              </div>
                          );
                        })}
                    </div>
                    <div className='project-info'>
                        {selectedProject &&
                            <ProjectInfo project={selectedProject}/>
                        }
                    </div>
                    <div className='mobile-project-info'>
                        {selectedProject &&
                            <ProjectInfo project={selectedProject}/>
                        }
                    </div>
                </div>
        </div>
    );
}

export default ExperiencePage;