import React from 'react';

const SkillsPage = () => {
    return (
        <div className='skill-page'>
            <div className='sub-header'>
                <p className='profile-title-text'>My</p>
                <p className='profile-title-text'>Skills</p>
                <p className='profile-title-sub-text'>
                    experience | main stack | additions
                </p>
            </div>

            <div className='skills-block'>
                    <div className="skill-column">
                        <p className="text-skills">Back-End</p>
                        <p className="text-skills">Front-End</p>
                        <p className="text-skills">Rest Api</p>
                        <p className="text-skills">DB design</p>
                        <p className="text-skills">OOP</p>
                        <p className="text-skills">Microservices</p>
                        <p className="text-skills">Multi-Tenancy APPs</p>
                    </div>
                    <div className="skill-column">
                        <p className="text-skills">PHP</p>
                        <p className="text-skills">Laravel</p>
                        <p className="text-skills">JavaScript</p>
                        <p className="text-skills">NodeJS</p>
                        <p className="text-skills">VueJS</p>
                        <p className="text-skills">React</p>
                        <p className="text-skills">MySQL</p>
                        <p className="text-skills">PostgreSQL</p>
                        <p className="text-skills">MongoDB</p>
                        <p className="text-skills">Redis</p>
                    </div>
                    <div className="skill-column">
                        <p className="text-skills">AWS</p>
                        <p className="text-skills">Docker</p>
                        <p className="text-skills">BrainTree payments</p>
                        <p className="text-skills">Stripe payments</p>
                        <p className="text-skills">npm</p>
                        <p className="text-skills">Nginx</p>
                        <p className="text-skills">Apache</p>
                        <p className="text-skills">OpenVPN</p>
                        <p className="text-skills">TaxJar</p>
                        <p className="text-skills">YouTube Api</p>
                        <p className="text-skills">GitHub</p>
                        <p className="text-skills">GitLab</p>
                        <p className="text-skills">BitBucket</p>
                    </div>
            </div>
        </div>
    );
}

export default SkillsPage