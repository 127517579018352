import {Route, Routes} from "react-router-dom";
import MainPage from "./components/MainPage";
import SkillsPage from "./components/SkillsPage";
import ExperiencePage from "./components/ExperiencePage";
import LinksPage from "./components/LinksPage";
import React from "react";

const Router = () => {
    return (
        <Routes>
            <Route exact path="/" element={<MainPage />} />
            <Route exact path="/skills" element={<SkillsPage />} />
            <Route exact path="/experience" element={<ExperiencePage />} />
            <Route exact path="/links" element={<LinksPage />} />
        </Routes>
    );
}

export default Router;